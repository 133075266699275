import React from 'react';
import Head from 'next/head';
import getConfig from 'next/config';
import { useRouter } from 'next/router';
import { useCodebase } from 'features/hooks/useCodebase';
import codebase from 'features/utils/codebase';

const { publicRuntimeConfig } = getConfig();

interface SEOProps {
  title?: any;
  titleSuffix?: boolean;
  description?: Object | string;
  ogDescription?: Object | string;
  ogImage?: Object | string;
  ogType?: string;
  noIndex?: boolean;
  noIndexButFollow?: boolean;
  blogArticleRobots?: boolean;
}

const SEO: React.FC<SEOProps> = React.memo(
  ({
    ogDescription,
    description,
    title,
    titleSuffix = true,
    ogImage,
    ogType = 'website',
    noIndex = publicRuntimeConfig.APP_ENV !== 'production',
    noIndexButFollow = false,
    blogArticleRobots = false,
  }: SEOProps) => {
    const locProps = {
      description,
      ogDescription,
      title,
      titleSuffix,
      ogImage,
      noIndex,
      noIndexButFollow,
      ogType,
      blogArticleRobots,
    };
    const router = useRouter();
    const isProd = publicRuntimeConfig.APP_ENV === 'production';

    const getHost = () => {
      if (codebase() === 'fwc') {
        if (isProd) return 'https://freeway-camper.com';
        return 'https://staging.freeway-camper.com';
      }
      if (isProd) return 'https://www.campiri.com';
      return 'https://www.staging.campiri.com';
    };

    const hostWithoutWWW = getHost().replace('www.', '');

    const isFWC = useCodebase('fwc');
    const { asPath } = router;

    const metaDescription = isFWC
      ? `Rent Camper and RVs with Freeway Camper: ✅ from 69€/Night ✅ VW Bulli ✅ RVs ✅ Campervans ➡️Book your Freedom now!`
      : 'Campiri je největší online půjčovna karavanů a obytných vozů. Vybírejte ze stovek obytných vozů z půjčoven i od soukromých majitelů z celé ČR.';

    const defaultValues = {
      title: isFWC
        ? 'Book your Freedom'
        : 'Campiri - pronájem karavanů a obytných vozů, půjčte si karavan na pár kliknutí',
      description: metaDescription,
      ogDescription: metaDescription,
      ogImage: isFWC
        ? `${getHost()}/fwc-static/og/image.jpg`
        : `${hostWithoutWWW}/static/images/og_image_campiri_main.png`,
      ogSiteName: isFWC ? 'Freeway Camper' : 'Campiri',
      email: isFWC ? 'info@freeway-camper.com' : 'jedeme@campiri.cz',
    };

    const finalProps: any = Object.keys(locProps).reduce((acc, propKey) => {
      acc[propKey] = locProps[propKey] || defaultValues[propKey];
      return acc;
    }, {});

    let desc = finalProps.description.replace(/(\r\n|\n|\r)/gm, '').replace(/(<([^>]+)>)/gi, '');
    let ogDesc = finalProps.ogDescription.replace(/(\r\n|\n|\r)/gm, '').replace(/(<([^>]+)>)/gi, '');

    const maxDescLength = 170;
    desc = desc.length > maxDescLength ? `${desc.substring(0, maxDescLength - 3)}...` : desc;
    ogDesc = ogDesc.length > maxDescLength ? `${ogDesc.substring(0, maxDescLength - 3)}...` : ogDesc;

    const titleComposed = `${finalProps.title} ${
      finalProps.titleSuffix ? ` | ${isFWC ? 'FreewayCamper' : 'Campiri.com'}` : ''
    }`;

    const faviconPath = isFWC ? 'fwc-static/favicon' : 'favicon';

    return (
      <Head>
        <title>{titleComposed}</title>
        {finalProps.noIndex === true && <meta name="robots" content="noindex, nofollow" />}
        {finalProps.noIndexButFollow === true && publicRuntimeConfig.APP_ENV === 'production' && (
          <meta name="robots" content="noindex, follow" />
        )}
        {finalProps.blogArticleRobots === true && publicRuntimeConfig.APP_ENV === 'production' && (
          <meta name="robots" content="index,follow,max-snippet:-1,max-image-preview:large" />
        )}
        <meta name="description" content={desc} />
        <meta httpEquiv="X-UA-Compatible" content="IE=Edge" />
        <meta name="HandheldFriendly" content="true" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="author" content={isFWC ? 'freeway-camper.com' : 'Campiri.com'} />

        <meta property="og:image" name="image" content={finalProps.ogImage} />
        <meta property="og:description" content={ogDesc} />
        <meta property="og:title" content={finalProps.title} />
        <meta property="og:type" content={finalProps.ogType} />
        <meta property="og:site_name" content={isFWC ? 'FreewayCamper' : 'Campiri'} />
        <meta property="og:url" content={`${getHost()}${asPath?.split('?')[0]}`} />
        <meta property="og:email" content={finalProps.email} />
        <meta property="fb:app_id" content={publicRuntimeConfig.FB_APP_ID} />
        {!isFWC && (
          <>
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={finalProps.title} />
            <meta name="twitter:site" content="@campiri" />
            <meta name="twitter:description" content={desc} />
            <meta name="twitter:creator" content="Prague Labs s.r.o." />
            <meta name="twitter:image" content={finalProps.ogImage} />
          </>
        )}

        <link rel="apple-touch-icon" sizes="180x180" href={`/${faviconPath}/apple-touch-icon.png`} />
        <link rel="icon" type="image/png" sizes="32x32" href={`/${faviconPath}/favicon-32x32.png`} />
        <link rel="icon" type="image/png" sizes="16x16" href={`/${faviconPath}/favicon-16x16.png`} />
        <link rel="manifest" href={`/${faviconPath}/site.webmanifest`} />
        <link rel="mask-icon" href={`/${faviconPath}/safari-pinned-tab.svg`} color={isFWC ? '#5bbad5' : '#1f2549'} />
        <meta name="msapplication-TileColor" content={isFWC ? '#da532c' : '#edeae3'} />
        <meta name="theme-color" content="#ffffff" />

        <meta name="google-signin-scope" content="profile email" />
        <meta name="google-signin-client_id" content={publicRuntimeConfig.GOOGLE_CLIENT_ID} />
      </Head>
    );
  }
);

export default SEO;

import getConfig from 'next/config';
import { Configuration } from '~/libs/api/configuration';
import { ListingMarketplaceApi } from '~/libs/api/apis/listing-marketplace-api';
import { ListingRentalCalendarPriceCalculateQuery } from '~/libs/api/models/listing-rental-calendar-price-calculate-query';
import { ListCalendarDaysQuery } from '~/libs/api/models/list-calendar-days-query';
import { ListingOperatorApi } from '~/libs/api/apis/listing-operator-api';
import { AddonMarketplaceApi } from '~/libs/api';

const { publicRuntimeConfig } = getConfig();

const getAccessToken = () => {
  const accessToken = window?.localStorage?.getItem('accessToken') || '';
  return accessToken;
};

const getApiConfig = () =>
  new Configuration({
    basePath: publicRuntimeConfig.API_HOST.replace(/\/+$/, ''),
  });

const getApiConfigCalculation = () =>
  new Configuration({
    basePath: publicRuntimeConfig.API_HOST.replace(/\/+$/, ''),
    accessToken: getAccessToken,
  });

const apiConfiguration = getApiConfig();
const apiConfigurationCalculation = getApiConfigCalculation();

const LISTINGS_API = new ListingMarketplaceApi(apiConfiguration, apiConfiguration.basePath);
const ADDON_API = new AddonMarketplaceApi(apiConfiguration, apiConfiguration.basePath);
const LISTINGS_API_CALCULATION = new ListingMarketplaceApi(
  apiConfigurationCalculation,
  apiConfigurationCalculation.basePath
);

const LISTING_OPERATOR_API = new ListingOperatorApi(apiConfigurationCalculation, apiConfigurationCalculation.basePath);

const getCarByID = async (id: number) => LISTINGS_API.apiV1MarketplaceListingsIdGet(id);
export const getAddonsByListingId = async (id: number) => ADDON_API.apiV1MarketplaceListingsIdAddonsAvailableGet(id);

const getFeaturedCars = async () => LISTINGS_API.apiV1MarketplaceListingsFeaturedPost();

const getCarCalculationById = async (
  id: number,
  listingRentalCalendarPriceCalculateQuery?: ListingRentalCalendarPriceCalculateQuery
) =>
  LISTINGS_API_CALCULATION.apiV1MarketplaceListingsIdCalculatePricePost(id, listingRentalCalendarPriceCalculateQuery);

const getCarUnavailability = async (id: number, listingUnavailabilityQuery?: ListCalendarDaysQuery) =>
  LISTINGS_API.apiV1MarketplaceListingsIdCalendarDaysPost(id, listingUnavailabilityQuery);

const getCarCalculationByIdOperator = async (
  id: number,
  listingRentalCalendarPriceCalculateQuery?: ListingRentalCalendarPriceCalculateQuery
) => LISTING_OPERATOR_API.apiV1OperatorListingsIdCalculatePricePost(id, listingRentalCalendarPriceCalculateQuery);

export { getFeaturedCars, getCarByID, getCarCalculationById, getCarUnavailability, getCarCalculationByIdOperator };

import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { addDays, differenceInDays, isBefore, isWithinInterval } from 'date-fns';
import { isValidDate } from 'rxjs/internal/util/isDate';
import { AddonCategory } from '~/libs/api';
import Wrench from 'ui/icons/ic_wrench.svg';
import Snow from 'ui/icons/ic_snow.svg';
import Insurance from 'ui/icons/ic_insurance.svg';
import Offroad from 'ui/icons/ic_offroad.svg';
import Kids from 'ui/icons/ic_kids-playground.svg';
import Cleaning from 'ui/icons/ic_cleaning.svg';
import Favorite from 'ui/icons/ic_favorite.svg';
import Bike from 'ui/icons/ic_bike-holder.svg';

const getCapacityData = (count: number) => {
  switch (count) {
    case 1:
      return `1 ${i18n._(t({ id: 'labels.place' }))}`;
    case 2:
    case 3:
    case 4:
      return `${count} ${i18n._(t({ id: 'labels.places1' }))}`;
    default:
      return `${count} ${i18n._(t({ id: 'labels.places2' }))}`;
  }
};

const getShowerData = (type: string) => {
  switch (type) {
    case 'OutdoorShower':
      return i18n._(t({ id: 'labels.shower.outdoor' }));
    case 'IndoorShower':
      return i18n._(t({ id: 'labels.shower.indoor' }));
    case 'NoShower':
      return i18n._(t({ id: 'labels.shower.noShower' }));
    default:
      return '';
  }
};

const getToiletData = (type: string) => {
  switch (type) {
    case 'Chemical':
      return i18n._(t({ id: 'labels.toilet.chemical' }));
    case 'Composting':
      return i18n._(t({ id: 'labels.toilet.composting' }));
    case 'Portable':
      return i18n._(t({ id: 'labels.toilet.portable' }));
    case 'NoToilet':
      return i18n._(t({ id: 'labels.toilet.noToilet' }));
    default:
      return '';
  }
};

export const getAddonsCategoryName = (category: AddonCategory) => {
  switch (category) {
    case 'ExtraMore':
      return {
        title: t({
          id: 'label.addon.extra',
          message: 'Extra',
        }),
        icon: <Wrench height={20} />,
      };

    case 'Winter':
      return {
        title: t({
          id: 'label.addon.winter',
          message: 'Winter',
        }),
        icon: <Snow fill="#999DA1" height={20} />,
      };

    case 'Insurance':
      return {
        title: t({
          id: 'label.addon.insurance',
          message: 'Insurance',
        }),
        icon: <Insurance fill="#999DA1" height={20} />,
      };

    case 'Transfer':
      return {
        title: t({
          id: 'label.addon.transfer',
          message: 'Transfer',
        }),
        icon: <Offroad height={20} />,
      };

    case 'Family':
      return {
        title: t({
          id: 'label.addon.family',
          message: 'Family',
        }),
        icon: <Kids fill="#999DA1" height={20} />,
      };

    case 'Cleaning':
      return {
        title: t({
          id: 'label.addon.cleaning',
          message: 'Cleaning',
        }),
        icon: <Cleaning height={20} />,
      };

    case 'Bestseller':
      return {
        title: t({
          id: 'label.addon.bestseller',
          message: 'Bestseller',
        }),
        icon: <Favorite height={20} />,
      };

    case 'Bike':
      return {
        title: t({
          id: 'label.addon.bike',
          message: 'Bike',
        }),
        icon: <Bike height={20} fill="#999DA1" />,
      };

    case 'KilometersPackages':
      return {
        title: t({
          id: 'label.addon.kilometersPackages',
          message: 'Bike',
        }),
      };

    case 'ServiceMore':
      return { title: i18n._(t({ id: 'label.addon.services' })), icon: <Wrench height={20} /> };

    case 'NotSet':
      return {
        title: i18n._(t({ id: 'label.addon.uncategorized' })),
        icon: <Wrench height={20} />,
      };

    default:
      return {
        title: i18n._(t({ id: 'label.addon.uncategorized' })),
        icon: <Wrench height={20} />,
      };
  }
};

const getWinterData = (type: string) => {
  switch (type) {
    case 'NotAllowed':
      return i18n._(t({ id: 'labels.winter.notAllowed' }));
    case 'Limited':
      return i18n._(t({ id: 'labels.winter.limited' }));
    case 'Allowed':
      return i18n._(t({ id: 'labels.winter.allowed' }));
    default:
      return '';
  }
};

const getVehicleType = (type: string) => {
  switch (type) {
    case 'Campervan':
      return i18n._(t({ id: 'vehicleTypes.campervan' }));
    case 'BuiltIn':
      return i18n._(t({ id: 'vehicleTypes.builtIn' }));
    case 'SemiIntegrated':
      return i18n._(t({ id: 'vehicleTypes.semiIntegrated' }));
    case 'Alcove':
      return i18n._(t({ id: 'vehicleTypes.alcove' }));
    case 'Integrated':
      return i18n._(t({ id: 'vehicleTypes.integrated' }));
    case 'Trailer':
      return i18n._(t({ id: 'vehicleTypes.trailer' }));
    case 'Other':
      return i18n._(t({ id: 'vehicleTypes.other' }));
    default:
      return '';
  }
};

const getFuelType = (type: string) => {
  switch (type) {
    case 'Gasoline':
      return i18n._(t({ id: 'fuelTypes.gasoline' }));
    case 'Diesel':
      return i18n._(t({ id: 'fuelTypes.diesel' }));
    case 'Lpg':
      return i18n._(t({ id: 'fuelTypes.lpg' }));
    case 'Electric':
      return i18n._(t({ id: 'fuelTypes.electric' }));
    case 'Hybrid':
      return i18n._(t({ id: 'fuelTypes.hybrid' }));
    default:
      return '';
  }
};

const getSmokingPolicy = (type: string) => {
  switch (type) {
    case 'Allowed':
      return i18n._(t({ id: 'labels.allowed' }));
    case 'NotAllowed':
      return i18n._(t({ id: 'labels.notAllowed' }));
    case 'OnlyIqos':
      return i18n._(t({ id: 'smoking.onlyIqos' }));
    default:
      return '';
  }
};

const getAnimalPolicy = (type: string) => {
  switch (type) {
    case 'Allowed':
      return i18n._(t({ id: 'labels.allowed' }));
    case 'NotAllowed':
      return i18n._(t({ id: 'labels.notAllowed' }));
    case 'OnlyDogs':
      return i18n._(t({ id: 'labels.onlyDogs' }));
    case 'OnlyTrained':
      return i18n._(t({ id: 'labels.onlyTrained' }));
    case 'OnRequest':
      return i18n._(t({ id: 'labels.onRequest' }));
    default:
      return '';
  }
};

const getWheelDriveData = (type: string) => {
  switch (type) {
    case 'FourByFour':
      return '4x4';
    case 'FourByTwo':
      return '4x2';
    case 'FrontWheelDrive':
      return i18n._(t({ id: 'drive.frontWheel' }));
    case 'BackWheelDrive':
      return i18n._(t({ id: 'drive.backWheel' }));
    default:
      return '';
  }
};

const getLicenseCategoryData = (type: string) => {
  switch (type) {
    case 'B':
      return 'B';
    case 'C':
      return 'C';
    case 'D':
      return 'D';
    case 'BE':
      return 'B+E';
    default:
      return '';
  }
};

const getTransmissionTypeData = (type: string) => {
  switch (type) {
    case 'Manual':
      return i18n._(t({ id: 'transmission.manual' }));
    case 'Automatic':
      return i18n._(t({ id: 'transmission.automatic' }));
    default:
      return '';
  }
};

const convertToSlug = (str) => {
  if (!str) return '';

  return str
    .trim() // remove whitespaces at the start and end of string
    .toLowerCase()
    .replace(/^-+/g, '') // remove one or more dash at the start of the string
    .replace(/[^\w-]+/g, '-') // convert any on-alphanumeric character to a dash
    .replace(/-+/g, '-') // convert consecutive dashes to singuar one
    .replace(/-+$/g, ''); // remove one or more dash at the end of the string
};

const getCarAvailability = ({ oneDayAvailability, availability, pickUpAvailability, dropOffAvailability }) => {
  const dropOffOrUnavailableOrUnset = dropOffAvailability === 0;
  const pickUpOrUnavailableOrUnset = pickUpAvailability === 0;
  const oneDayOrUnavailableOrUnset = oneDayAvailability === 0 || oneDayAvailability === null;
  const isUnavailable =
    availability === 0 && pickUpOrUnavailableOrUnset && oneDayOrUnavailableOrUnset && dropOffOrUnavailableOrUnset;
  const isPickUpOnly = dropOffOrUnavailableOrUnset && pickUpAvailability >= 1;
  const isDropOffOnly = pickUpOrUnavailableOrUnset && dropOffAvailability >= 1;
  const isOneDayAvailabilityOnly = dropOffOrUnavailableOrUnset && pickUpOrUnavailableOrUnset && oneDayAvailability >= 1;

  return {
    isDropOffOnly,
    isPickUpOnly,
    isUnavailable,
    isOneDayAvailabilityOnly,
  } as const;
};

const isRangeValid = ({ pickUpOnlyDays, dropOffOnlyDays, startDate, endDate }) => {
  if (!isValidDate(startDate) || !isValidDate(endDate) || isBefore(endDate, startDate)) return false;
  let isPickUpOnly;
  let isDropOffOnly;
  if (Math.abs(differenceInDays(endDate, startDate)) <= 1) {
    isPickUpOnly = pickUpOnlyDays.some((day) => isWithinInterval(new Date(day), { start: startDate, end: endDate }));
    isDropOffOnly = dropOffOnlyDays.some((day) => isWithinInterval(new Date(day), { start: startDate, end: endDate }));
  } else {
    isPickUpOnly = pickUpOnlyDays.some((day) =>
      isWithinInterval(new Date(day), { start: addDays(startDate, 1), end: endDate })
    );
    isDropOffOnly = dropOffOnlyDays.some((day) =>
      isWithinInterval(new Date(day), { start: startDate, end: addDays(endDate, -1) })
    );
  }

  return !isPickUpOnly;
};

export {
  getCapacityData,
  getCarAvailability,
  getShowerData,
  getToiletData,
  getWinterData,
  getVehicleType,
  getFuelType,
  getSmokingPolicy,
  getAnimalPolicy,
  getWheelDriveData,
  isRangeValid,
  getLicenseCategoryData,
  getTransmissionTypeData,
  convertToSlug,
};

import styled, { css } from 'styled-components';
import { deviceMaxWidth } from '~/src/styles/breakpoints';
import { resolveInCodebase, resolveThemeValue } from 'ui/design/utils';
import tokens from 'ui/design/tokens/tokens';

const PageWrapper = styled.div<{ ignoreOverflow?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding: 0px 32px;

  ${({ ignoreOverflow }) =>
    !ignoreOverflow &&
    css`
      overflow: hidden;
    `};

  @media ${deviceMaxWidth.phone} {
    padding: 0 16px;
  }

  &.missionPageWrapper {
    @media ${deviceMaxWidth.phone} {
      margin: -24px auto 0 !important;
    }
  }

  ${resolveInCodebase(
    'fwc',
    css`
      padding: 0 !important;
    `
  )}
`;

const BookingPageWrapper = styled.div<{ mobilePadding: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  background-color: white;

  &.greyBg {
    background-color: ${resolveThemeValue(tokens.color.backgrounds.surfaceContrast, '#f9f8f6')};
  }

  @media ${deviceMaxWidth.tablet} {
    padding: ${(props) => (props.mobilePadding ? '0 16px' : '0')};
  }
`;

const StaticSection = styled.section`
  width: 100%;
  max-width: 1040px;
  padding: 120px 0 80px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media ${deviceMaxWidth.tablet} {
    background: none !important;
    padding: 48px 0 40px 0;
  }
`;

const ErrorText = styled.div`
  font-size: 12px;
  color: var(--color-error-red);
  margin-top: 8px;
`;

export { PageWrapper, StaticSection, BookingPageWrapper, ErrorText };

import { ListingRentalCalendarPriceCalculateQueryResult } from '~/libs/api/models/listing-rental-calendar-price-calculate-query-result';
import * as t from '../types';

export const setCalculationData = (calculation: ListingRentalCalendarPriceCalculateQueryResult) => dispatch => {
  dispatch({
    type: t.SET_CALCULATION_DATA,
    payload: calculation,
  });
};

export const setCalculationLoading = (loading: boolean) => dispatch => {
  dispatch({
    type: t.SET_CALCULATION_LOADING,
    payload: loading,
  });
};

export const setCalculationError = (hasError: boolean | string) => dispatch => {
  dispatch({
    type: t.SET_CALCULATION_ERROR,
    payload: hasError,
  });
};

export const setCalculationPromoCode = (promoCodeToSend: null | string) => dispatch => {
  dispatch({
    type: t.SET_CALCULATION_PROMO_CODE,
    payload: promoCodeToSend,
  });
};

import useBreakpoints, { SMALL_SCREEN_QUERY } from 'features/hooks/useBreakpoints';
import { Box } from 'ui/primitives/box';
import tokens from 'ui/design/tokens/tokens';
import { FlexCol } from 'ui/primitives/flex';
import { Body, Headline } from 'ui/components/primitives/typography/CustomTypography';
import { Swiper, SwiperSlide } from 'swiper/react';
// eslint-disable-next-line import/no-unresolved
import { Pagination } from 'swiper/modules';
import DynamicSegmentPreview from './DynamicSegmentPreview';
import styled from 'styled-components';
import { resolveThemeValue } from 'ui/design/utils';

import React, { ReactNode } from 'react';
import { Language } from 'features/utils/routing';
import LocalizedLink from 'features/routing/LocalizedLink';
import { useLocalizedRouter } from '~/src/components/LocalizedLink';
import { useSelector } from 'react-redux';
import { RouteType } from 'features/routing/types';

type AllowedTokens = 'de' | 'en' | 'it';

interface Props {
  title: ReactNode;
  subtitle?: ReactNode;
  showPagination?: boolean;
  items: {
    title: string;
    category?: string;
    links: {
      [key in AllowedTokens]: {
        route: RouteType;
        linkParams?: any;
      };
    };
    image: string;
  }[];
  trackFunction?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => unknown;
}

export default function DynamicSegmentCarousel({
  title,
  showPagination = true,
  subtitle,
  items,
  trackFunction,
}: Props) {
  const settings = useSelector((state: any) => state.settings);

  return (
    <FlexCol>
      <Headline $size="h3">{title}</Headline>
      {subtitle && (
        <Body $maxWidth="520px" $pt={tokens.spacing.spacing2Xl} $size="l">
          {subtitle}
        </Body>
      )}
      <Box $pt={tokens.spacing.spacing4Xl}>
        <Swiper
          modules={[showPagination && Pagination].filter(Boolean)}
          pagination={{
            type: 'bullets',
            el: '#pagination',
            clickable: true,
          }}
          breakpoints={{
            600: {
              slidesPerView: 2.3,
            },
            1000: {
              slidesPerView: 4,
              spaceBetween: 32,
            },
          }}
          slidesPerView={1.4}
          spaceBetween={16}
        >
          {items?.map((item) => {
            const linkElement = item.links?.[settings.language];

            if (!linkElement) return null;

            return (
              <SwiperSlide key={item.title}>
                <LocalizedLink linkParams={linkElement.linkParams} href={linkElement.route} onClick={trackFunction}>
                  {/* @ts-ignore */}
                  <Box $borderRadius={tokens.radius.radiusL} $background="black">
                    <DynamicSegmentPreview image={item.image} title={item.title} subtitle={item.category} />
                  </Box>
                </LocalizedLink>
              </SwiperSlide>
            );
          })}
        </Swiper>
        <StyledPagination $pl="72px" $mt="20px" id="pagination" />
      </Box>
    </FlexCol>
  );
}

const StyledPagination = styled(Box)`
  gap: ${resolveThemeValue(tokens.spacing.spacingS)};
  display: flex;
  .swiper-pagination-bullet {
    width: 40px;
    height: 4px;
    border-radius: ${resolveThemeValue(tokens.radius.radiusL)};
    background: ${resolveThemeValue(tokens.color.items.fillIconDark)};
  }
`;

import { AxiosRequestConfig } from 'axios';
import getConfig from 'next/config';
import { BookingMarketplaceApi } from '~/libs/api/apis/booking-marketplace-api';
import { Configuration } from '~/libs/api/configuration';
import { UpdateBookingBillingInformationCommand } from '~/libs/api/models/update-booking-billing-information-command';
import { CreateOpenInquiryCommand } from '~/libs/api/models/create-open-inquiry-command';
import { UpdateBookingAddonsCommand } from '~/libs/api/models/update-booking-addons-command';
import { AddCancellationProtectionCommand } from '~/libs/api/models/add-cancellation-protection-command';
import { ChangeBookingIntentRenterDetailsCommand } from '~/libs/api/models/change-booking-intent-renter-details-command';
import { AddBookingIntentCommand } from '~/libs/api/models/add-booking-intent-command';
import { BookingIntentUpdateRenterCommand } from '~/libs/api/models/booking-intent-update-renter-command';
import { BookingIntentCustomerInfoUpdateCommand } from '~/libs/api/models/booking-intent-customer-info-update-command';
import { CalculateFwcBookingAddonsQuery, UpdateFwcBookingAddonsCommand } from '~/libs/api';

const getAccessToken = () => {
  if (typeof window !== 'undefined') {
    const accessToken = window?.localStorage?.getItem('accessToken');
    return accessToken;
  }
  return null;
};

const { publicRuntimeConfig } = getConfig();

const getApiConfig = () =>
  new Configuration({
    basePath: publicRuntimeConfig.API_HOST.replace(/\/+$/, ''),
    accessToken: getAccessToken,
  });

const apiConfiguration = getApiConfig();

const BOOKING_API = new BookingMarketplaceApi(apiConfiguration, apiConfiguration.basePath);
// const STRIPE_API = new StripeWebhookApi(apiConfiguration, apiConfiguration.basePath);
const createBookingIntent = async (addBookingIntentCommand?: AddBookingIntentCommand) =>
  BOOKING_API.apiV1MarketplaceIntentsPost(addBookingIntentCommand);
const createBookingPreCheckoutIntent = async (addBookingIntentCommand?: AddBookingIntentCommand) =>
  BOOKING_API.apiV1MarketplaceIntentsOpenCheckoutPost(addBookingIntentCommand);

const updateBookingIntent = async (
  id: string,
  changeBookingIntentRenterDetailsCommand?: ChangeBookingIntentRenterDetailsCommand
) => BOOKING_API.apiV1MarketplaceIntentsIdPut(id, changeBookingIntentRenterDetailsCommand);

const moveBookingToRequestState = async (id: string, note?: string) =>
  BOOKING_API.apiV1MarketplaceIntentsIdRequestPost(id, note);
const moveBookingToRequestStateWithOpenCheckoutToken = async (id: string, note?: string, openCheckoutToken?: string) =>
  BOOKING_API.apiV1MarketplaceIntentsIdOpenCheckoutRequestPost(id, openCheckoutToken, note);

export const addFWCAddonsToOpenCheckout = async (
  id: string,
  openCheckoutToken: string,
  update: UpdateFwcBookingAddonsCommand
) => BOOKING_API.apiV1MarketplaceIntentsIdOpenCheckoutFwcAddonsPut(id, openCheckoutToken, update);

export const addFWCAddonsToFullCheckout = async (id: string, update: UpdateFwcBookingAddonsCommand) =>
  BOOKING_API.apiV1MarketplaceBookingsIdFwcAddonsPut(id, update);

const setAddons = async (id: string, updateBookingAddonsCommand?: UpdateBookingAddonsCommand) =>
  BOOKING_API.apiV1MarketplaceBookingsIdAddonsPut(id, updateBookingAddonsCommand);
const setAddonsOpenCheckout = async (
  id: string,
  updateIncompleteBookingIntentAddonsCommand?: UpdateBookingAddonsCommand,
  openCheckoutToken?: string
) =>
  BOOKING_API.apiV1MarketplaceIntentsIdOpenCheckoutAddonsPut(
    id,
    openCheckoutToken,
    updateIncompleteBookingIntentAddonsCommand
  );

const getRequestById = async (id: string) => BOOKING_API.apiV1MarketplaceRequestsIdGet(id);
export const addPromoCodeToBooking = async (id: string, promoCode: string) =>
  BOOKING_API.apiV1MarketplaceBookingsIdPromoCodePut(id, promoCode);
export const removePromoCodeFromBooking = async (id: string) =>
  BOOKING_API.apiV1MarketplaceBookingsIdPromoCodeDelete(id);

export const addPromoCodeToOpenCheckout = async (id: string, openCheckoutToken: string, promoCode: string) =>
  BOOKING_API.apiV1MarketplaceIntentsIdOpenCheckoutPromoCodePut(id, openCheckoutToken, promoCode);
export const removePromoCodeFromOpenCheckout = async (id: string, openCheckoutToken: string) =>
  BOOKING_API.apiV1MarketplaceIntentsIdOpenCheckoutPromoCodeDelete(id, openCheckoutToken);

const getIntentById = async (id: string) => BOOKING_API.apiV1MarketplaceIntentsIdGet(id);

const getReservationById = async (id: string) => BOOKING_API.apiV1MarketplaceReservationsIdGet(id);

const createOpenBooking = async (captcha: string, payload: CreateOpenInquiryCommand) =>
  BOOKING_API.apiV1MarketplaceBookingsOpenInquiryPost(captcha, payload);

// const postWebHookSuccess = async (id: string) => STRIPE_API.apiV1StripeWebhooksPaymentIntentSucceededDebugPost(id);

const getBookingState = async (id: string) => BOOKING_API.apiV1MarketplaceBookingsIdStateGet(id);
const setRenterToIntent = async (
  id: string,
  openCheckoutToken?: string,
  bookingIntentUpdateRenterCommand?: BookingIntentUpdateRenterCommand
) =>
  BOOKING_API.apiV1MarketplaceIntentsIdOpenCheckoutRenterPut(id, openCheckoutToken, bookingIntentUpdateRenterCommand);
const setCurrentSessionToIntent = async (id: string) =>
  BOOKING_API.apiV1MarketplaceIntentsIdOpenCheckoutRenterFromSessionPut(id);
const getUserMissingFields = async (id: string, completionToken?: string) =>
  BOOKING_API.apiV1MarketplaceIntentsIdOpenCheckoutRenterMissingFieldsGet(id, completionToken);
const renterAccountComplete = async (
  id: string,
  completionToken?: string,
  bookingIntentCustomerInfoUpdateCommand?: BookingIntentCustomerInfoUpdateCommand
) =>
  BOOKING_API.apiV1MarketplaceIntentsIdRenterCompletePatch(id, completionToken, bookingIntentCustomerInfoUpdateCommand);
const getIncompleteIntent = async (id: string, openCheckoutToken?: string) =>
  BOOKING_API.apiV1MarketplaceIntentsIdOpenCheckoutGet(id, openCheckoutToken);

const postWithdrawRequest = async (id: string, reason?: string) =>
  BOOKING_API.apiV1MarketplaceRequestsIdWithdrawPost(id, reason);

const requestPaymentByAccount = async (id: string) =>
  BOOKING_API.apiV1MarketplaceBookingsIdNotifyManagerPaymentV2Post(id);
const requestPaymentByAccountWithOpenCheckoutToken = async (id: string, openCheckoutToken?: string) =>
  BOOKING_API.apiV1MarketplaceIntentsIdOpenCheckoutNotifyManagerPaymentV2Post(id, openCheckoutToken);

const getOfferCustomer = async (id: string) => BOOKING_API.apiV1MarketplaceOffersIdGet(id);

const denyOfferCustomer = async (id: string, reason?: string) =>
  BOOKING_API.apiV1MarketplaceOffersReferenceCodeDenyPost(id, reason);

const getDocumentByID = async (id: string, guid: string, options) =>
  BOOKING_API.apiV1MarketplaceReservationsIdDocumentsGuidGet(id, guid, options);

export const calculateAddonPrice = (id: string, body: CalculateFwcBookingAddonsQuery) =>
  BOOKING_API.apiV1MarketplaceBookingsIdCalculateAddonsPricePost(id, body);

export const calculateIntentsAddonPrice = (
  id: string,
  openCheckoutToken: string,
  body: CalculateFwcBookingAddonsQuery
) => BOOKING_API.apiV1MarketplaceIntentsIdCalculateAddonsPricePost(id, openCheckoutToken, body);

const updateBookingBillingAddress = async (
  id: string,
  updateBookingBillingInformationCommand?: UpdateBookingBillingInformationCommand,
  options?: AxiosRequestConfig
) => BOOKING_API.apiV1MarketplaceBookingsIdBillingInformationPut(id, updateBookingBillingInformationCommand, options);
// eslint-disable-next-line max-len
const updateBookingBillingAddressWithOpenCheckoutToken = async (
  id: string,
  openCheckoutToken?: string,
  updateBookingBillingInformationCommand?: UpdateBookingBillingInformationCommand,
  options?: AxiosRequestConfig
) =>
  BOOKING_API.apiV1MarketplaceIntentsIdOpenCheckoutBillingInformationPut(
    id,
    openCheckoutToken,
    updateBookingBillingInformationCommand,
    options
  );

const getCancelationProtectionPrice = async (referenceCode: string) =>
  BOOKING_API.apiV1MarketplaceBookingsReferenceCodeCancellationProtectionCalculatePriceGet(referenceCode);
const getCancelationProtectionPriceWithOpenCheckoutToken = async (referenceCode: string, openCheckoutToken?: string) =>
  BOOKING_API.apiV1MarketplaceIntentsIdOpenCheckoutCancellationProtectionCalculatePriceGet(
    referenceCode,
    openCheckoutToken
  );

const postCancelationProtectionData = async (
  referenceCode: string,
  createCancellationProtectionCommand?: AddCancellationProtectionCommand
) =>
  BOOKING_API.apiV1MarketplaceBookingsReferenceCodeCancellationProtectionPost(
    referenceCode,
    createCancellationProtectionCommand
  );
// eslint-disable-next-line max-len
const postCancelationProtectionDataWithOpenCheckoutToken = async (
  referenceCode: string,
  openCheckoutToken?: string,
  createCancellationProtectionCommand?: AddCancellationProtectionCommand
) =>
  BOOKING_API.apiV1MarketplaceIntentsIdOpenCheckoutCancellationProtectionPost(
    referenceCode,
    openCheckoutToken,
    createCancellationProtectionCommand
  );

const removeCancelationProtectionFromBooking = async (referenceCode: string) =>
  BOOKING_API.apiV1MarketplaceBookingsReferenceCodeCancellationProtectionDelete(referenceCode);
const removeCancelationProtectionFromBookingWithOpenCheckoutToken = async (
  referenceCode: string,
  openCheckoutToken?: string
) => BOOKING_API.apiV1MarketplaceIntentsIdOpenCheckoutCancellationProtectionDelete(referenceCode, openCheckoutToken);
const getBookingInsuranceContractPdf = (referenceCode: string) =>
  BOOKING_API.apiV1MarketplaceBookingsReferenceCodeCarInsuranceContractPdfGet(referenceCode, {
    responseType: 'blob',
  });
const getBookingInsuranceContractPdfForOpenCheckout = (referenceCode: string, openChekoutToken: string) =>
  BOOKING_API.apiV1MarketplaceIntentsIdOpenCheckoutCarInsuranceContractPdfGet(referenceCode, openChekoutToken, {
    responseType: 'blob',
  });

export {
  requestPaymentByAccountWithOpenCheckoutToken,
  postCancelationProtectionDataWithOpenCheckoutToken,
  removeCancelationProtectionFromBookingWithOpenCheckoutToken,
  getCancelationProtectionPriceWithOpenCheckoutToken,
  getRequestById,
  getReservationById,
  updateBookingIntent,
  createBookingIntent,
  moveBookingToRequestState,
  createOpenBooking,
  getBookingState,
  getIntentById,
  postWithdrawRequest,
  requestPaymentByAccount,
  getOfferCustomer,
  denyOfferCustomer,
  getDocumentByID,
  updateBookingBillingAddress,
  setAddons,
  getIncompleteIntent,
  getCancelationProtectionPrice,
  postCancelationProtectionData,
  removeCancelationProtectionFromBooking,
  getBookingInsuranceContractPdf,
  getBookingInsuranceContractPdfForOpenCheckout,
  createBookingPreCheckoutIntent,
  setRenterToIntent,
  setCurrentSessionToIntent,
  setAddonsOpenCheckout,
  getUserMissingFields,
  renterAccountComplete,
  moveBookingToRequestStateWithOpenCheckoutToken,
  updateBookingBillingAddressWithOpenCheckoutToken,
};

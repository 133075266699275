import { Trans } from '@lingui/react';
import React, { MutableRefObject } from 'react';
import styled, { css } from 'styled-components';
import uuidv4 from 'features/utils/uuidv4';
import { resolveThemeValue } from '../../design/utils';
import tokens from '../../design/tokens/tokens';

interface Props {
  icon?: string | null;
  name: string;
  label?: string;
  isSelected: boolean;
  onCheckboxChange?: (e?: any) => void;
  boxOnLeft?: boolean;
  isGrey?: boolean;
  errorMessage?: any;
  labelTranslComp?: any;
  showErrorMessage?: boolean;
  inline?: boolean;
  className?: string;
  id?: string;
  disabled?: boolean;
  required?: boolean;
  partialCheck?: boolean;
  readOnly?: boolean;
  inputRef?: MutableRefObject<any>;
}

function Checkbox({
  icon = null,
  name,
  inline = true,
  label,
  isSelected,
  onCheckboxChange,
  className = '',
  boxOnLeft = false,
  isGrey = false,
  errorMessage,
  labelTranslComp,
  showErrorMessage = false,
  partialCheck = false,
  id = uuidv4(),
  disabled = false,
  required = false,
  readOnly = false,
  inputRef,
}: Props) {
  const getLabel = () => {
    if (labelTranslComp) return labelTranslComp;
    if (label) return <Trans id={label} />;
    return null;
  };

  return (
    <>
      <Wrapper
        className={`${boxOnLeft ? 'boxOnLeft ' : ''}
                          ${errorMessage ? 'hasError ' : ''} ${className}
      `}
      >
        <div className={isSelected ? 'checkbox checked' : 'checkbox'}>
          <input
            ref={inputRef}
            id={id}
            type="checkbox"
            name={name}
            readOnly={readOnly}
            checked={isSelected}
            value={isSelected ? 'true' : 'false'}
            onChange={onCheckboxChange}
            className={`hidden ${partialCheck ? 'partialCheck' : ''}`}
            disabled={disabled}
          />
          <label
            htmlFor={id}
            className={isGrey ? `isGrey checkBoxLabel` : 'checkBoxLabel'}
            data-testid={`react-checkbox-${name || ''}`}
          >
            {icon && <i className={icon} />}
            <InlineSpan inline={inline} required={required}>
              {getLabel()}
            </InlineSpan>
          </label>
        </div>
      </Wrapper>
      {errorMessage && showErrorMessage && <ErrorWrapper>{errorMessage}</ErrorWrapper>}
    </>
  );
}

const InlineSpan = styled.span<{ inline?: boolean; required: boolean }>`
  display: inline-flex;
  gap: 0;

  ${({ inline }) =>
    !inline &&
    css`
      display: inherit;
    `}
  & div::after {
    content: ${({ required }) => required && '*'};
  }
`;

const Wrapper = styled.div`
  .checkbox {
    position: relative;
  }

  input[type='checkbox'] {
    /* width: 1.6rem;
    height: 1.6rem; */
  }

  input[type='checkbox'].hidden {
    z-index: -1;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0 !important;
    outline: 0;
    width: 20px;
    height: 20px;
    vertical-align: top;
  }

  input[type='checkbox'].hidden + label {
    width: 100%;
    cursor: pointer;
    user-select: none;
    position: relative;
    display: flex;
    align-items: center;
    outline: 0;
    font-family: var(--font-poppins);
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    color: var(--color-dark-blue);
    padding-right: 25px;

    > i {
      font-size: 1.25rem;
      margin-right: 13px;

      &:before {
        color: var(--color-dark-blue);
      }
    }
  }

  input[type='checkbox']:disabled + label {
    cursor: auto;
  }

  input[type='checkbox'].hidden + label.isGrey {
    color: var(--color-dark-grey);
    font-size: 16px;
  }

  input[type='checkbox'].hidden + label > a {
    color: inherit;
  }

  input[type='checkbox'].hidden + label:before {
    position: absolute;
    top: 0;
    right: 0;
    width: 18px;
    height: 18px;
    content: '';
    background: #fff;
    border-radius: 4px;
    transition:
      border 0.1s ease,
      opacity 0.1s ease,
      transform 0.1s ease,
      border-color 0.1s ease;
    border: 1px solid ${resolveThemeValue(tokens.color.border.border, 'var(--color-grey)')};
  }

  input[type='radio'].hidden + label:before {
    border-radius: 50%;
  }

  input[type='checkbox'].hidden:not(:disabled) + label:hover:before {
    border-color: var(--color-dark-grey);
  }

  input[type='checkbox'].hidden:checked + label:before {
    background-color: ${resolveThemeValue(tokens.color.items.fillIconSuccess, 'var(--color-green)')};
    border-color: ${resolveThemeValue(tokens.color.items.fillIconSuccess, 'var(--color-green)')};
  }

  input[type='checkbox'].hidden:checked ~ label:after {
    content: 'L';
    font-family: arial;
    font-weight: bold;
    display: flex;
    justify-content: center;
    line-height: 18px;
    opacity: 1;
    transform: scaleX(-1) rotate(-35deg);
    color: #fff;
  }

  input[type='checkbox'].hidden.partialCheck:checked ~ label:after {
    content: '-';
    font-family: arial;
    font-weight: bold;
    display: flex;
    justify-content: center;
    line-height: 18px;
    opacity: 1;
    transform: scaleX(-1);
    color: #fff;
  }

  input[type='checkbox'].hidden + label:after {
    position: absolute;
    font-size: 0.75em;
    top: 0;
    right: 0;
    width: 20px;
    height: 20px;
    text-align: center;
    opacity: 0;
    border-radius: 4px;
    color: rgba(0, 0, 0, 0.87);
    transition:
      border 0.1s ease,
      opacity 0.1s ease,
      transform 0.1s ease,
      box-shadow 0.1s ease;
  }

  &.boxOnLeft {
    input[type='checkbox'].hidden + label:before {
      left: 0;
    }

    input[type='checkbox'].hidden + label {
      padding-left: 30px;
      padding-right: 0px;
      line-height: unset;

      > i {
        margin-left: 13px;
      }
    }

    input[type='checkbox'].hidden {
      left: 0;
    }

    input[type='checkbox'].hidden + label:after {
      left: 0;
    }
  }

  &.hasError {
    input[type='checkbox'].hidden + label:before {
      border-color: ${resolveThemeValue(tokens.color.text.textError, 'var(--color-error-red)')};
    }

    label,
    div {
      color: ${resolveThemeValue(tokens.color.text.textError, 'var(--color-error-red)')} !important;
    }
  }
`;

const ErrorWrapper = styled.div`
  font-size: 10px;
  color: ${resolveThemeValue(tokens.color.text.textError, 'var(--color-error-red)')};
  margin-top: 6px;
`;

export default Checkbox;

import tokens from 'ui/design/tokens/tokens';
import Image from 'next/image';
import { FlexCol } from 'ui/primitives/flex';
import { Headline } from 'ui/components/primitives/typography/CustomTypography';
import { Box } from 'ui/primitives/box';
import styled from 'styled-components';
import { resolveThemeValue } from 'ui/design/utils';

interface Props {
  title: string;
  subtitle?: string;
  image: string;
}

export default function DynamicSegmentPreview({ subtitle, title, image }: Props) {
  return (
    <Wrapper $overflow="hidden" $width="275px" $maxWidth="275px" $height="320px">
      <StyledImage src={image} sizes="280px" fill={true} alt={title} />
      <FlexCol
        $offset={{
          bottom: '24px',
          left: '12px',
        }}
        $gap={tokens.spacing.spacingM}
        $position="absolute"
      >
        {subtitle && (
          <Headline $size="h6" $color={tokens.color.text.textContrastPrimary}>
            {subtitle}
          </Headline>
        )}
        <Headline $size="h4" $color={tokens.color.text.textContrastPrimary}>
          {title}
        </Headline>
      </FlexCol>
    </Wrapper>
  );
}

const StyledImage = styled(Image)`
  overflow: hidden;
  object-fit: cover;
  border-radius: ${resolveThemeValue(tokens.radius.radiusL)};
`;

const Wrapper = styled(Box)`
  cursor: pointer;
  &:hover {
    img {
      mask-image: linear-gradient(0deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 1) 25%);
    }
  }
  border-radius: ${resolveThemeValue(tokens.radius.radiusL)};
  overflow: hidden;

  img {
    mask-image: linear-gradient(0deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 1) 35%);
  }
`;
